<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../../store'
import Utils from '../../lib/utils'
import VueResource from 'vue-resource'
import $ from 'jquery'

import { EventBus } from '../../lib/event_bus.js'

Vue.use(VueResource)

// Components
import Consents     from './consents.vue'
import EmailInput   from './email_input.vue'

export default Vue.component('ConsentsContainer', {
  store,
  components: {
    Consents: () => import('./consents.vue'),
    EmailInput: () => import('./email_input.vue')
  },
  data() {
    return {
      screen: null,
      email: null,
      cookies: [],
      guid: Utils.guid(),
      template: null
    }
  },
  computed: {
    ...mapState([
    ]),

    consents() {
      var consents = []

      for (let field of this.screen.fields) {
        consents.push({
          agree: field.agree || false,
          consent_type_key: field.consent_type_key,
          site_consent_screen_field_id: field.id,
          required: field.required,
          applies_to_third_parties: field.applies_to_third_parties
        })
      }
      return consents
    },
    hijackForm() {
      return this.screen && this.screen.hijack_form
    },
    $template() {
      if (this.screen == null)
        return null

      return $(this.screen.template)
    },
    $element() {
      return $(this.$el)
    }
  },
  mounted() {
    // Get email when typing
    EventBus.$on(`${this.guid}-email-filled`, (value) => {
      this.email = value
    })

    this.mountComponents()
  },
  methods: {
    mountComponents() {
      let element = this.$element.find(this.screen.template_insert_selector)
      if (element.length < 1 && this.test)
        Utils.logNotice(`Template Insert Selector with selector ${this.screen.template_insert_selector} NOT found`)

      switch (this.screen.insertion_type) {
      case "appendTo":
        element.append(this.$template.get(0))
        break
      case "prependTo":
        element.prepend(this.$template.get(0))
        break
      case "insertAfter":
        element.after(this.$template.get(0))
        break
      case "insertBefore":
        element.before(this.$template.get(0))
        break
      }

      const mappedScreenFields = this.screen.fields.map(
        f => ({ ...f, agree: f.prechecked || !!f.agree })
      )

      this.screen.fields = mappedScreenFields

      new Consents({
        el: this.$template.get(0), // getting an HTML element, not a jQuery object
        data: {
          screen: this.screen
        }
      })

      this.mountEmailInput()
      this.mountSubmitButton()

      this.appendCSS()
    },

    mountEmailInput() {
      // If we are in checkout, get email from billing address
      if (this.screen.billing_email_selector && this.screen.billing_email_selector.length > 0) {
        let html = $(document).find(this.screen.billing_email_selector).html()

        if (html != undefined) {
          var extractedEmail = Utils.extractEmails(html)
          if (extractedEmail)
            this.email = extractedEmail[0]
        } else {
          Utils.logNotice(`Email from element ${this.screen.billing_email_selector} NOT found`)
        }
      }

      if (this.screen.global_email_selector == "1") {
        this.emailInput = $(document).find(this.screen.email_selector)
      } else {
        this.emailInput = this.$element.find(this.screen.email_selector)
      }

      if (this.emailInput.length > 0) {
        this.emailInput.attr('v-model', 'email')

        // Email input
        new EmailInput({
          el: this.emailInput.get(0),
          data: {
            email: (this.emailInput.val() || ''),
            guid: this.guid
          }
        })
      } else {
        if (this.emailInput.length < 1 && this.test && this.email == null) {
          Utils.logNotice(`(SCREEN ID: ${this.screen.id}) Email selector ${this.screen.email_selector} inside ${this.$element} NOT found!`, "MoaD")
        }
      }
    },

    mountSubmitButton() {
      if (this.screen.global_button_selector == "1") {
        var submitButton = $(document).find(this.screen.button_selector)
      }
      else {
        var submitButton = this.$element.find(this.screen.button_selector)
      }

      if (submitButton.length < 1 && this.test) {
        Utils.logNotice(`(SCREEN ID: ${this.screen.id}) Element with selector ${this.screen.button_selector} inside ${this.$element} NOT found!`, "MoaD")
      }

      var $this = this

      submitButton.on('click', function(event) {
        // Remove existing error messages
        document.querySelectorAll(".gy-gdpr-consent-error,.gy-gdpr-consent-success").forEach(e => e.parentNode.removeChild(e));

        if ($this.requiredConsentGiven()) {
          if ($this.hijackForm) {
            event.preventDefault()
          }

          $this.submitConsent()

          if ($this.$element.find('.gy-pay-button').length > 0) {
            event.preventDefault()
            $this.$store.dispatch('openCreditCardModal')
          }
        }
        else {
          submitButton.prop('disabled', true) // Disable submit button in order to prevent posting

          $this.appendErrorMessage($this.requiredConsentMessage)
          submitButton.prop('disabled', false)

          event.preventDefault() // Disable js functioning e.g. submit() of registration_form class
        }
      })
    },

    appendCSS() {
      // Append CSS
      var style       = document.createElement('style')
      style.type      = 'text/css'
      style.innerHTML = this.screen.css
      document.getElementsByTagName('head')[0].appendChild(style)
    },

    setDomainAndToken() {
      if (window.gy_moad != undefined) {
        this.domain = window.gy_moad.domain
        this.token  = window.gy_moad.token

        if (window.gy_moad.locale != undefined) {
          this.locale = window.gy_moad.locale
        } else {
          this.locale = "el"
        }
      } else {
        this.domain = window.location.hostname
        this.token  = ''
        this.locale = "el"
      }
    },

    sendEmailTo3rdParties() {
      var analytics = window.gyAnalytics

      if (analytics != undefined) {
        if (analytics.ContactPigeon != undefined)
          analytics.ContactPigeon.sendEmail(this.email, this.thirdPartiesConsentGiven())
      }
    },

    thirdPartiesConsentGiven() {
      for (let consent of this.consents) {
        if (consent.agree && consent.applies_to_third_parties) {
          return true
        }
      }
      return false
    },

    requiredConsentGiven() {
      for (let consent of this.consents) {
        if (consent.required && !consent.agree) {
          return false
        }
      }
      return true
    },

    submitConsent() {
      this.setDomainAndToken()

      var $this = this
      var subscriberGroupIds = this.emailInput.data("subscriberGroupIds")

      this.$http.post("consents", {
        domain: $this.domain,
        site_consent_screen_id: $this.screen.id,
        email: $this.email,
        subscriber_group_ids: subscriberGroupIds,
        consents: $this.consents,
        required_consent_given: $this.requiredConsentGiven(),
        locale: window.locale || $this.locale
      }, {
        headers: {
          "X-Authorization-Token": $this.token
        }
      }).then(response => {
        Utils.logNotice(`[MoaD] Consent SENT to API with params ${$this.email}`, "MoaD", {
          domain: $this.domain,
          site_consent_screen_id: $this.screen.id,
          email: $this.email,
          consents: $this.consents,
          locale: window.locale || $this.locale
        })

        this.sendEmailTo3rdParties()

        if (this.hijackForm) {
          this.showSuccessMessage(response.body.message)
        }
      }, response => {
        // error callback
        if (this.hijackForm) {
          this.appendErrorMessage(response.body.message)
        }

        // Special handle
        if (response.status == 406) {
        } else if (response.status == 500) {
        }
      })

      if (!this.email) {
        Utils.logNotice(`(SCREEN ID: ${this.screen.id}) Email NOT found! Check if input field component has been initialized or ${this.screen.email_selector} is correct`, "MoaD")
      }
    },

    appendErrorMessage(message) {
      let html = '<div class="gy-gdpr-consent-error">'
      html += message
      html += '</div>'

      if (this.screen.error_message_selector && this.screen.error_message_selector.length > 2) {
        $(this.screen.error_message_selector).each(function() {
          this.insertAdjacentHTML('afterbegin', html)
        })
      }
      else {
        $(this.screen.template_insert_selector).each(function() {
          this.insertAdjacentHTML('afterbegin', html)
        })
      }
    },

    showSuccessMessage(message) {
      let html = '<div class="gy-gdpr-consent-success">'
      html += message
      html += '</div>'

      if (this.screen.success_message_selector && this.screen.success_message_selector.length > 2) {
        $(this.screen.success_message_selector).get(0).insertAdjacentHTML('afterbegin', html)
      }
      else {
        this.$element.find(this.screen.template_insert_selector).get(0).insertAdjacentHTML('beforebegin', html)
      }
    }
  }
})
</script>
